/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Header from "./header"
import Footer from "./footer"
import "../../styles/index.scss"

const Layout = ({ children }) => {

  return (
    <>
      <div id="app" className="app">
        <Header />
            <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

export default Layout
