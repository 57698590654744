import React from 'react';
import wrapperStyles from './wrapper.module.scss'

const Wrapper = (props) => {
  const { children } = props;
  return (
    <div className={wrapperStyles.wrapper}>
      {children}
    </div>
  );
};

export default Wrapper;