import React from "react"
import Wrapper from './wrapper'
import styles from './footer.module.scss'

const Footer = () => (
    <footer className={styles.footer}>
        <Wrapper>
            <ul className={styles.list}>
                <li className={styles.listItem}>
                    <a className={styles.listLink} href="https://twitter.com/digital_marty">Twitter</a>
                </li>
                <li className={styles.listItem}>
                    <a className={styles.listLink} href="https://github.com/aussiemarty">GitHub</a>
                </li>
                <li className={styles.listItem}>
                    <a className={styles.listLink} href="mailto:martin@digitalmarty.com">Mail</a>
                </li>
            </ul>
        </Wrapper>
    </footer>
)

export default Footer